import '../../../service/domain/jackpot.js';
(function () {
  'use strict';

  const component = { name: 'lindaWidgetJackpot' };

  controller.$inject = ['$scope', 'jackpot', '$state'];

  function controller($scope, _jackpot, $state) {
    //$scope.preloader = true;
    $scope.jackpot = 0;
    $scope.currency = $state.params.lang === 'ja' ? 'USD' : 'EUR';

    $scope.preloader = true;
    _jackpot.collection().then(
      (answer) => {
        $scope.jackpot = Math.round(answer.result.latest);
        $scope.preloader = false;
      },
      (answer) => {
        $scope.preloader = false;
      }
    );
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
