(function () {
  'use strict';

  const component = { name: 'lindaGameItemSimple' };

  const classLib = {
    demo: 'linda-has-demo',
    label: 'linda-has-label',
  };

  controller.$inject = ['$scope', '$element'];

  function controller($scope, $element) {
    $scope.game = {};

    this.$onChanges = function () {
      $scope.game = this.game;

      if (this.game && this.game.demo) $element[0].classList.add(classLib.demo);

      if (this.game && this.game.label && this.game.label.length) $element[0].classList.add(classLib.label);
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {
      game: '<',
    },
  });
})();
