
const aliases = {
  home: {
    page: true,
    name: 'app.root.layer1.layer2.layer3.home',
  },
  login: {
    page: false,
    name: 'login',
  },
  history: {
    page: true,
    name: 'app.root.layer1.layer2.layer4.balance-history'
  },
  promo: {
    page: true,
    name: 'app.root.layer1.layer2.promo.list',
  },
  registration: {
    page: false,
    name: 'login',
  },
  paynplay: {
    page: false,
    name: 'login',
  },
  deposit: {
    page: true,
    name: 'app.root.layer1.layer2.layer4.cashbox.deposit',
  },
  realGame: {
    page: true,
    name: 'app.root.real',
  },
  initialLimitPage: {
    page: true,
    name: 'app.root.deposit-page',
  },
  // initialTimeLimitPage: {
  //     page: true,
  //     name: 'app.root.time-page'
  // },
  missedData: {
    page: true,
    name: 'app.root.missedData',
  },
  notFound: {
    page: true,
    name: 'app.root.layer1.layer2.not-found',
  },
  depositLimits: {
    page: true,
    name: 'app.root.layer1.layer2.layer4.selfcontrol',
  },
  verification: {
    page: true,
    name: 'app.root.layer1.layer2.layer4.profile.verification',
  }
};


export {aliases};
