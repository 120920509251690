import '../../service/domain/banner.js';
import '../../service/rx/system$.js';
import {filter} from 'rxjs/operators';

(function () {
  'use strict';

  const component = { name: 'lindaSliderMain' };

  controller.$inject = ['$scope', 'banner', '$timeout', 'system$'];

  function controller($scope, _banner, $timeout, _system$) {
    $scope.collection = [];
    $scope.preloader = false;
    $scope.transition = false;

    const o = {};

    this.$onInit = () => {
      o.category = this.category;
      o.place = this.place;
      getCollection(o);

      _system$
        .pipe(filter((m) => m.action === 'login'))
        .subscribe((m) => {
          o.auth = true;
          getCollection(o);
        });
      _system$
        .pipe(filter((m) => m.action === 'logout'))
        .subscribe((m) => {
          o.auth = false;
          getCollection(o);
        });
    };

    $scope.getCurrent = (event, slick, currentSlide, nextSlide) => {
      $scope.current = nextSlide;
      $scope.transition = true;
      $timeout(() => {
        $scope.transition = false;
      }, 600);
    };

    function getCollection(c) {
      $scope.current = 0;
      $scope.loaded = false;
      $scope.preloader = true;
      _banner.collection(c).then(
        (answer) => {
          $scope.collection = answer.result;
          $scope.loaded = true;
          $scope.preloader = false;
        },
        (answer) => {
          $scope.preloader = false;
        }
      );
    }
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {
      category: '<',
      place: '<',
    },
  });
})();
