import './default/config.js';
import './default/env.js';
import './default/service-worker.js';
import './default/lang.js';
import './default/sentry.js';
import './default/tap-event.js';

import './bootstrap/init.js';
import './bootstrap/get.js';
import './bootstrap/auth.js';
import './bootstrap/*.js';
import './helper/device.js';
import './helper/device.js';
import './helper/browser.js';
import './modules/casinoApp.js';
import './modules/*.js';

import './helper/sportScripts.js';
import './helper/onfidoScripts.js';
import './components/base.js';
import './pipe/**/*.js';
import './service/routerResolvers/*.js';
import '../dist/component-imports.js';
import './directive/**/*.js';
import '../src/directive/**/*.js';

