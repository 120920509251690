import splash$ from '../../../app/service/rx/splash$.js';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

(function () {
  'use strict';

  const directive = { name: 'yoyoButtonAnimation' };

  controller.$inject = ['splash$'];

  function controller(_splash$) {
    const splashBehaviorSubject$ = new BehaviorSubject('');

    _splash$.subscribe(splashBehaviorSubject$.next);

    function link(scope) {
      let subscription;
      let buttonClass = '.bankid-banner-form__btn';
      let animationClassLive = 'animation-live';
      let animationClassWidth = 'animation-width';
      let morphPathIdStart = 'morph1';
      let morphPathIdEnd = '#morph2';

      function bankidAnimation() {
        setTimeout(function () {
          let button = document.querySelector(`${buttonClass}`);
          button.classList.add(`${animationClassLive}`);
          setTimeout(() => {
            button.classList.add(`${animationClassWidth}`);
          }, 1300);
          let tl = gsap.timeline({}),
            first = document.getElementById(`${morphPathIdStart}`);

          tl.to(
            first,
            {
              morphSVG: `${morphPathIdEnd}`,
              duration: 0.3,
            },
            '=0.9'
          ).to(
            first,
            {
              morphSVG: first,
              duration: 0.6,
              ease: 'bounce.out',
            },
            '=.1'
          );
        }, 0);
      }

      subscription = splashBehaviorSubject$
        .pipe(
          filter((m) => m === 'loaded'),
        )
        .subscribe(bankidAnimation);

      scope.$on('$destroy', () => {
        subscription.unsubscribe();
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(
    directive.name,
    controller
  );
})();
