import '../../service/domain/account.js';
import '../../service/helper.js';
(function () {
  'use strict';

  const component = { name: 'swedenAuthTokenRestorePopup' };

  controller.$inject = ['$scope', 'account', 'helper'];

  function controller($scope, _account, _helper) {
    $scope.preloader = false;

    $scope.formData = {
      email: '',
    };

    $scope.submit = _helper.form.validator((form) => {
      $scope.preloader = true;
      _account
        .svTokenRestore($scope.formData)
        .then()
        .finally(() => {
          $scope.preloader = false;
        });
    });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
