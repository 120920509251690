(function () {

  'use strict';

  const directive = { name: 'checkWb' };

  controller.$inject = [];

  function controller(){

    function link( scope, element, attrs ){
      scope.setActivePromotionByIndex = (index) => {
        scope.activePromotion = scope.collection[index];
        scope.activePromotionIndex = index;
        scope.formData.trigger_codes = scope.activePromotion.trigger_codes;
        scope.formData.enable_trigger_codes = true;
      }

      scope.resetActivePromotion = () => {
        scope.activePromotion = {};
        scope.formData.enable_trigger_codes = false;
      }
      scope.$watchCollection(
        "collection",
        function( ) {
          if( scope.collection.length && scope.collection[0].trigger_codes ){
            scope.formData.trigger_codes = scope.collection[0].trigger_codes;
            scope.activePromotion = scope.collection[0];
            scope.formData.enable_trigger_codes = true;
          }
        }
      );

    }

    return {
      restrict: 'A',
      link
    };
  }

  app.directive( directive.name, controller );

})();
