(function () {
    'use strict';

    const directive = {
        name: 'clearFirstLogin'
    };

    function controller() {

        function link(scope, element, attrs) {
            localStorage.removeItem('firstLogin');
        }

        return {
            restrict: 'A',
            link: link
        };
    }

    app.directive(directive.name, controller);
})();
