import '../../../service/domain/infoblock.js';
(function () {
  'use strict';

  const component = { name: 'lindaWidgetRewards' };

  controller.$inject = ['$scope', 'infoblock'];

  function controller($scope, _infoblock) {
    $scope.reward = [];

    this.$onChanges = function () {
      $scope.preloader = true;
      _infoblock.reward().then(
        (answer) => {
          $scope.reward = answer.result;
          $scope.preloader = false;
        },
        (answer) => {
          $scope.preloader = false;
        }
      );
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
