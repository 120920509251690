const _temp0 = require("../amount-filter.js");
const _temp1 = require("../check-wb.js");
const _temp2 = require("../clear-first-login.js");
const _temp3 = require("../getLocalStorage.js");
const _temp4 = require("../hide-popup.js");
const _temp5 = require("../input-bankid.js");
const _temp6 = require("../open-cashier-on-first-login.js");
const _temp7 = require("../pgw-widget-page.js");
const _temp8 = require("../set-autofocus.js");
const _temp9 = require("../set-first-login.js");
const _temp10 = require("../set-step.js");
const _temp11 = require("../setLocalStorage.js");
const _temp12 = require("../yoyo-splider.js");
const _temp13 = require("../animation/yoyo-btn.js");
const _temp14 = require("../animation/yoyo-button-animation.js");

const _temp15 = require("../smart-banner/smart-banner-btn.js");
const _temp16 = require("../smart-banner/smart-banner-qr.js");

const _temp17 = require("../yoyo-img/yoyo-img.js");

module.exports = {
  "amount-filter": _temp0,
  "check-wb": _temp1,
  "clear-first-login": _temp2,
  "getLocalStorage": _temp3,
  "hide-popup": _temp4,
  "input-bankid": _temp5,
  "open-cashier-on-first-login": _temp6,
  "pgw-widget-page": _temp7,
  "set-autofocus": _temp8,
  "set-first-login": _temp9,
  "set-step": _temp10,
  "setLocalStorage": _temp11,
  "yoyo-splider": _temp12,
  "animation":   {
    "yoyo-btn": _temp13,
    "yoyo-button-animation": _temp14
  },
  "smart-banner":   {
    "smart-banner-btn": _temp15,
    "smart-banner-qr": _temp16
  },
  "yoyo-img":   {
    "yoyo-img": _temp17
  }
}