const $_PGW = {
  enabled: true,
  layoutMode: 'row',
  hideCloseButton: true,
  cssVars: {
    "--pgw-font-family": "'Roboto'",
    "--pgw-background-primary": "transparent",
    "--pgw-surface-primary": "#FFD180",
    "--pgw-surface-secondary": "#F9FBE7",
    "--pgw-surface-secondaryHover": "#FFEED1",
    "--pgw-surface-accent": "#FFEDFF",
    "--pgw-surface-accentHover": "#FEE3FF",
    "--pgw-surface-buttonPrimary": "#23B578",
    "--pgw-surface-buttonPrimaryHover": "#00BE6E",
    "--pgw-surface-buttonPrimaryPress": "#009255",
    "--pgw-surface-buttonPrimaryDisabled": "#D2B2CA",
    "--pgw-surface-buttonSecondary": "#AB69AD",
    "--pgw-surface-buttonSecondaryHover": "#A937AD",
    "--pgw-surface-buttonSecondaryPress": "#7B1E7E",
    "--pgw-surface-buttonSecondaryDisabled": "#D2B2CA",
    "--pgw-surface-buttonTertiary": "#FFFFFF 70%",
    "--pgw-surface-buttonTertiaryHover": "#FFFFFF 50%",
    "--pgw-surface-buttonTertiaryPress": "#FFFFFF",
    "--pgw-surface-buttonTertiaryDisabled": "#D2B2CA",
    "--pgw-surface-inputPrimary": "#FFFFFF",
    "--pgw-surface-inputSecondary": "#FFFFFF",
    "--pgw-surface-inputTertiary": "#DCDBCA",
    "--pgw-surface-tab": "#F9FBE7 70%",
    "--pgw-surface-tabHover": "#9746A5 70%",
    "--pgw-surface-tabPress": "#9746A5",
    "--pgw-surface-negative": "#EA4642",
    "--pgw-surface-positiveAlpha": "#23B5781a",
    "--pgw-surface-skeleton": "#F6F6F926",
    "--pgw-surface-userchoiсePrimary": "#35D290",
    "--pgw-surface-userchoiсeSecondary": "#35D290",
    "--pgw-surface-userchoiсeTertiary": "#FFFFFF",
    "--pgw-surface-userchoiсeIndicatorPrimary": "#FFFFFF",
    "--pgw-surface-userchoiсeIndicatorPrimaryHover": "#FFFFFF",
    "--pgw-surface-userchoiсeIndicatorSecondary": "#EA4642",
    "--pgw-surface-userchoiсeIndicatorSecondaryHover": "#EA4642",
    "--pgw-text-primary": "#3E3E3E",
    "--pgw-text-secondary": "#553156",
    "--pgw-text-tertiary": "#F0F0F4",
    "--pgw-text-buttonPrimary": "#FFFFFF",
    "--pgw-text-buttonPrimaryDisabled": "#F0F0F4",
    "--pgw-text-buttonSecondary": "#FFFFFF",
    "--pgw-text-buttonSecondaryDisabled": "#F0F0F4",
    "--pgw-text-positive": "#23B578",
    "--pgw-text-warning": "#F69230",
    "--pgw-text-negative": "#EA4642",
    "--pgw-text-constant": "#FFFFFF",
    "--pgw-icon-primary": "#3E3E3E",
    "--pgw-icon-secondary": "#553156",
    "--pgw-icon-tertiary": "#F0F0F4",
    "--pgw-icon-buttonPrimary": "#FFFFFF",
    "--pgw-icon-buttonPrimaryDisabled": "#F0F0F4",
    "--pgw-icon-buttonSecondary": "#FFFFFF",
    "--pgw-icon-positive": "#23B578",
    "--pgw-icon-warning": "#F69230",
    "--pgw-icon-negative": "#EA4642",
    "--pgw-stroke-primary": "#F0F0F4",
    "--pgw-stroke-secondary": "#FFA800",
    "--pgw-stroke-inputPrimary": "#F0F0F4",
    "--pgw-stroke-inputSecondary": "#AA69AC",
    "--pgw-stroke-accent": "#AA69AC",
    "--pgw-stroke-accentHover": "#AA69AC",
    "--pgw-stroke-positive": "#23B578",
    "--pgw-stroke-positiveAlpha": "#23B5784d",
    "--pgw-stroke-warning": "#F69230",
    "--pgw-stroke-negative": "#EA4642",
    "--pgw-stroke-userchoiсe": "#AA69AC",
    "--pgw-stroke-userchoiсeHover": "#A857AB",
    "--pgw-divider-primary": "#DCBFDD",
    "--pgw-border-radius-s": "100px",
    "--pgw-border-radius-m": "8px",
    "--pgw-border-radius-l": "12px",
    "--pgw-border-radius-xl": "16px"
  },
};

export { $_PGW };

