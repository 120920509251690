(function () {
  'use strict';

  const directive = { name: 'hidePopup' };

  controller.$inject = [];

  function controller() {

    function link(scope, element, attrs) {
      document.querySelector('.hide-popup').classList.toggle('is-hidden');
    }

    return {
      restrict: 'A',
      link: link
    };
  }

  app.directive(directive.name, controller);
})();
