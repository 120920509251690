(function () {
  'use strict';

  const config = {
    currencyList: {
      fi: { key: 'EUR', name: '', icon: '€' },
      sv: { key: 'SEK', name: '', icon: 'kr' },
    },
    countries: {
      fi: { code: 'FI', name: 'Finland' },
      sv: { code: 'SE', name: 'Sweden' },
    },
    defaultAmountRange: {
      fi: [100, 300, 1000],
      sv: [500, 1000, 5000],
    },
    defaultAmount: {
      fi: 100,
      sv: 1000,
    },
    amountRange: {
      fi: [10, 5000],
      sv: [100, 50000],
    },
  };

  app.constant('entercash.config', config);
})();
