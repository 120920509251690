import '../../../service/domain/infoblock.js';
(function () {
  'use strict';

  const component = { name: 'lindaWidgetBigwins' };

  controller.$inject = ['$scope', 'infoblock'];

  function controller($scope, _infoblock) {
    $scope.collection = [];

    this.$onChanges = function () {
      const o = {
        type: this.type || 'week',
        count: this.count || 5,
      };

      $scope.preloader = true;
      _infoblock.win(o).then(
        (answer) => {
          $scope.collection = answer.result;
          $scope.preloader = false;
        },
        (answer) => {
          $scope.preloader = false;
        }
      );
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
    bindings: {
      type: '<',
      count: '<',
    },
  });
})();
