(function () {
  'use strict';

  const directive = {
    name: 'setFirstLogin'
  };

  function controller() {

    function link(scope, element, attrs) {

      const clickHandler = () => {
        localStorage.setItem('firstLogin', true);
      };

      element[0].addEventListener('click', clickHandler);

      scope.$on('$destroy', () => {
        element[0].removeEventListener('click', clickHandler);
      });
    }

    return {
      restrict: 'A',
      link: link
    };
  }

  app.directive(directive.name, controller);
})();
