import splash$ from '../../../app/service/rx/splash$.js';
import { filter, first  } from 'rxjs/operators';

(function () {
  'use strict';

  const directive = { name: 'yoyoBtn' };

  controller.$inject = ['splash$'];

  function controller(_splash$) {

    function link(scope, elem, attrs) {

      const btn = elem[0];
      const text = btn.querySelector('.btn-bankid__text');
      const arr = btn.querySelector('.btn-bankid__arr');
      const icon = btn.querySelector('.btn-bankid__pay');

      gsap.set( btn , { width: 48 })
      gsap.set( text , { display: 'none', opacity: 0 })
      gsap.set( icon , { display: 'none', opacity: 0 })

      const tl = gsap.timeline({paused: true})
        .to( btn, {
          x: 120 ,
          yoyo: true,
          repeat: 1 ,
          delay: 0.3,
          duration: 0.3,
        })
        .to( btn, { width: 268, duration: 0.3, ease: "elastic.inOut(1, 0.5)" }, "-=0.1")
        .to( arr, { x: -22 }, "-=0.4")
        .to( icon , { display: 'block', opacity: 1, }, "-=0.4" )
        .to( text , { display: 'block', opacity: 1, }, "-=0.5" )

      _splash$
        .pipe(
          filter((m) => m === 'loaded'),
          first()
        )
        .subscribe( ()=>{
          tl.play(0);
        })
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
