import user from '../../app/service/domain/user.js';
import user$ from '../../app/service/rx/user$.js';
import state from '../../app/service/domain/state.js';


(function () {
    'use strict';

    const directive = {
        name: 'openCashierOnFirstLogin'
    };

    controller.$inject = [
        'user',
        'user$',
        'state',
        '$state'
    ];

    function controller(_user, _user$, _state, _$state) {
        function link() {
            _user$.subscribe(() => {
                setTimeout(() => {
                    const firstLogin = localStorage.getItem('firstLogin')
                    if (_user.status && firstLogin) {
                        _state.goto('deposit');
                    }
                }, 500)
            });
        }

        return {
            restrict: 'A',
            link
        };
    }

    app.directive(directive.name, controller);
})();
