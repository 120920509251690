import '../../service/helper.js';
import '../../service/configs/config.js';
import '../../service/configs/entercash.js';
import '../../service/domain/state.js';
import '../../service/domain/bank.js';
(function () {
  'use strict';

  const component = { name: 'swedenBannerEntercash' };

  controller.$inject = ['$scope', 'helper', 'config', 'entercash.config', 'state', '$state', 'bank'];

  function controller($scope, _helper, _config, _entercash_config, _state, $state, _bank) {
    const defaultAmount = _entercash_config.defaultAmount;

    $scope.formData = {
      amount: defaultAmount['sv'],
    };

    $scope.defaultAmountRange = _entercash_config.defaultAmountRange['sv'];
    $scope.amountRange = _entercash_config.amountRange['sv'];

    $scope.submit = _helper.form.validator((form) => {
      const state = _state.get('fastRegistration');
      if (state) {
        _bank.entercash.formData.amount = $scope.formData.amount;
        $state.go(state.name);
      }
    });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
