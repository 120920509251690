(function () {
  'use strict';

  const directive = { name: 'setAutofocus' };

  controller.$inject = ['$timeout'];

  function controller(_timeout) {
    function link(scope, element) {
      _timeout(() => {
        if (element[0].value === '0') {
          element[0].value = '';
        }
        element[0].focus();
      });
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
