(function () {
  'use strict';

  const component = { name: 'lindaGameHall' };

  controller.$inject = ['$scope', '$state'];

  function controller($scope, $state) {
    $scope.category = null;

    this.$onInit = function () {
      $scope.category = $state.params.name;
    };
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
  });
})();
